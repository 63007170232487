<template lang="pug">
.form
  .form__block(v-show="!send")
    .form__fields
      .form__field
        .input-text
          input.input-text__input(
            type="text"
            v-model="fieldName"
            :placeholder="placeholderFieldName"
          )

      .form__field
        .input-text
          input.input-text__input(
            type="tel"
            v-model="fieldPhone"
            :placeholder="placeholderFieldPhone"
            v-maska="['+7 (###) ##-##-##', '+7 (###) ###-##-##']"
            :class="{'input-text__input-error': v$.fieldPhone.$invalid && (fieldPhone.length > 0)}"
          )

      .form__field.form__field-calendar
        label.input-text.input-text-calendar
          span.input-text__label Дата
          input.input-text__input(
            type="text"
            v-model="fieldDate"
            placeholder="01.01.2023"
            v-maska="['##.##.####', '##.##.####']"
          )
          svg.input-text__icon
            use(xlink:href="#contact-calendar")

    .form__consent
      label.consent
        input.consent__input(
          type="checkbox"
          v-model="consent"
          value="consent"
          checked="checked"
        )
        span.consent__label(v-html="formConsent")

    .form__button
      .button.button-pink(
        :class="{'button-disable': v$.$invalid}"
        @click="getSend()"
      )
        .button__text(v-html="formButton")

  .form__block(v-show="send")
    p.center(v-html="formSuccessText")

</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'
export default {
  name: 'AppForm',

  setup () {
    return { v$: useVuelidate() }
  },

  props: [
    "formSettingTitle",
    "formSettingSubject",
    "formButton",
    "formConsent",
    "formSuccessText",
    "formFieldName",
    "formFieldPhone",
    "formFieldPageName",
    "formFieldPageId"
  ],

  data () {
    return {
      fieldName: '',
      fieldPhone: '',
      fieldDate: '',
      files: '',
      consent: ['consent'],
      send: false,
      placeholderFieldName: this.formFieldName,
      placeholderFieldPhone: this.formFieldPhone + ': *',
    }
  },

  methods: {
    ...mapActions([
      'Send'
    ]),

    getSend () {
      if (!this.v$.$invalid) {
        this.send = !this.send
        this.Send({
          setting: {
            title: this.formSettingTitle,
            subject: this.formSettingSubject
          },

          fields: [
            {
              title: this.formFieldName,
              value: this.fieldName
            },
            {
              title: this.formFieldPhone,
              value: this.fieldPhone
            },
            {
              title: 'Дата',
              value: this.fieldDate
            },
            {
              title: this.formFieldPageName,
              value: this.formFieldPageId
            }
          ],
          files: this.files
        })

        this.fieldName = this.fieldPhone = this.fieldDate = ''

        setTimeout(() => {
          this.send = !this.send
        }, 5000)
      }
    }
  },

  validations () {
    return {
      fieldPhone: {
        required,
        minLength: minLength(18),
        maxLength: maxLength(18)
      },

      consent: {
        required
      }
    }
  }
}
</script>
