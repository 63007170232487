/*eslint-disable no-unused-vars*/

import { createApp } from 'vue'
import Maska from 'maska'
import form from '@/store/form'
import './assets/js/css/ImportCss'
import { Fancybox } from '@fancyapps/ui'
import AppForm from '@/components/Form/AppForm.vue'
import AppFormPopup from '@/components/Form/Popup/AppFormPopup.vue'
import SwiperSlider from '@/assets/js/Swiper/Swiper'
import MainMenu from '@/assets/js/Menu/MainMenu'
import MenuToggle from '@/assets/js/Menu/MenuToggle'
import PriceButton from '@/assets/js/Price/PriceButton/PriceButton'
import Button from '@/assets/js/Button/Button'

// Подключаем глобально jQuery
const $ = window.$ = window.jQuery = require('jquery')

document.addEventListener('DOMContentLoaded', () => {
    Fancybox.bind('[data-subscribe]')

    new SwiperSlider()
    new MainMenu()
    new MenuToggle()
    new PriceButton()
    new Button()

    if(document.getElementById('block--form') != null) {
        createApp({})
            .component('block-form', AppForm)
            .use(Maska)
            .use(form)
            .mount('#block--form')
    }

    if(document.getElementById('block--form-popup') != null) {
        createApp({})
            .component('block-form-popup', AppFormPopup)
            .use(Maska)
            .use(form)
            .mount('#block--form-popup')
    }
})
