/*global $*/
/*eslint no-undef: "error"*/

export default class MenuToggle {
    classBlock = 'menu-toggle--block'
    classBlockOpen = 'menu-toggle-open'
    classMenu = 'menu-toggle--menu'
    classMenuOpen = 'menu-open'
    classPageMenu = 'page-menu'

    constructor () {
        let self = this
        let objBlock = $(`.${self.classBlock}`)
        let objMenu = $(`.${self.classMenu}`)

        objBlock.click(function () {
            $(this).toggleClass(self.classBlockOpen)
            objMenu.toggleClass(self.classMenuOpen)
        })

        $(`body`).mouseup(function (e) {
            let buttonObj = $(`.${self.classPageMenu}`)

            if ((!buttonObj.is(e.target)) && (buttonObj.has(e.target).length === 0) && (objBlock.hasClass(self.classBlockOpen))) {
                objBlock.removeClass(self.classBlockOpen)
                $(`.${self.classMenu}`).removeClass(self.classMenuOpen)
            }
        })
    }
}
