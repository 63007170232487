import Swiper, {Autoplay, EffectCoverflow, Navigation, Pagination, Thumbs, Lazy} from 'swiper'
Swiper.use([Navigation, Pagination, EffectCoverflow, Autoplay, Thumbs, Lazy])

export default class SwiperSlider {
    createSlider () {
        return new Swiper('#slider--slider', {
            slidesPerView: 1,
            spaceBetween: 30,
            navigation: {
                nextEl: '.slider--slider-next',
                prevEl: '.slider--slider-prev',
            },
            pagination: {
                el: '.swiper-pagination--slider',
                clickable: true
            },
            breakpoints: {}
        })
    }

    createDoctor () {
        return new Swiper('#slider--doctor', {
            slidesPerView: 4,
            spaceBetween: 30,
            navigation: {
                nextEl: '.slider--doctor-next',
                prevEl: '.slider--doctor-prev',
            },
            pagination: {
                el: '.swiper-pagination--doctor',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                414: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                480: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                514: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                991: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                1240: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                },
                1440: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                }
            }
        })
    }

    createGallery () {
        return new Swiper('#slider--gallery', {
            slidesPerView: 1,
            spaceBetween: 20,
            navigation: {
                nextEl: '.slider--gallery-next',
                prevEl: '.slider--gallery-prev',
            },
            pagination: {
                el: '.swiper-pagination--gallery',
                clickable: true
            },
            breakpoints: {}
        })
    }

    createSert () {
        return new Swiper('#slider--sert', {
            slidesPerView: 2,
            spaceBetween: 20,
            navigation: {
                nextEl: '.slider--sert-next',
                prevEl: '.slider--sert-prev',
            },
            pagination: {
                el: '.swiper-pagination--sert',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                320: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                360: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                375: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                414: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },
                480: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },
                514: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                640: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                },
                991: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                },
                1024: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                1240: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                }
            }
        })
    }

    constructor () {
        if(document.getElementById('slider--slider') != null) {
            this.createSlider()
        }

        if(document.getElementById('slider--doctor') != null) {
            this.createDoctor()
        }

        if(document.getElementById('slider--gallery') != null) {
            this.createGallery()
        }

        if(document.getElementById('slider--sert') != null) {
            this.createSert()
        }
    }
}
