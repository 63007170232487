/*global $*/
/*eslint no-undef: "error"*/

export default class MainMenu {
    classItem = 'menu--item'
    classItemOpen = 'menu__item-open'
    classItemSub = 'menu--sub'
    classButton = 'menu--button'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            if ($(this).closest(`.${self.classItem}`).hasClass(self.classItemOpen)) {
                $(this).closest(`.${self.classItem}`).removeClass(self.classItemOpen)
            } else {
                $(`.${self.classItemOpen}`).removeClass(self.classItemOpen)
                $(this).closest(`.${self.classItem}`).addClass(self.classItemOpen)
            }
        })

        $(`body`).mouseup(function (e) {
            let objSub = $(`.${self.classItemSub}`)
            let countItemOpen = $(`.${self.classItemOpen}`).length

            if ((!objSub.is(e.target)) && (objSub.has(e.target).length === 0) && (countItemOpen === 1)) {
                $(`.${self.classItem}`).removeClass(self.classItemOpen)
            }
        })
    }
}
