/*global $*/
/*eslint no-undef: "error"*/

export default class PriceButton {
    classButton = 'price--button'
    classBlock = 'price--block'
    classItem = 'price--item'
    classHidden = 'price__item-hidden'
    classButtonText = 'button__text'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let textView = $(this).data('view')
            let textHidden = $(this).data('hidden')

            $(this).closest(`.${self.classBlock}`).find(`.${self.classItem}`).toggleClass(self.classHidden)

            if ($(this).find(`.${self.classButtonText}`).text() === textHidden) {
                $(this).find(`.${self.classButtonText}`).text(textView)
            } else {
                $(this).find(`.${self.classButtonText}`).text(textHidden)
            }
        })
    }
}
